<template lang="pug">
.container.mb-24.mt-12.flex-grow.flex.items-center
  .w-full.max-w-4xl.mx-auto
    baseH1Pages.mb-10 {{ title }}
    .shadow-xl.rounded-3xl.p-10.border.border-gray-100
      slot
</template>

<script>
export default {
  name: "BasicPages",
  components: {},
  props: { title: String },
};
</script>

<style lang="scss" scoped>
h1 {
  @apply text-center text-3xl font-bold text-gray-700 mb-10;
}

::v-deep() {
  h2 {
    @apply text-xl font-bold text-gray-600 mb-2 mt-4;
  }

  h3 {
    @apply text-lg font-bold text-gray-500 mb-2 mt-4;
  }

  p {
    @apply text-base text-gray-500 pb-2;
  }

  ul {
    @apply list-disc list-inside my-5 text-gray-600 text-sm;
    li {
      + li {
        @apply pt-2;
      }
    }
  }
}
</style>
